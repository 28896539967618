.button {
    font-family: 'Roboto', sans-serif;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    background-color: darkgreen;
    color: white;
    font-size: 26px;
    padding: 18px 32px;
    border: none;
    cursor: pointer;
    border-radius: 15px;
    text-align: center;
  }

  .button:hover {
    background-color: green;
    color: lightgreen;
  }

  a:link {
    color: white;
    background-color: transparent;
    text-decoration: none;
  }