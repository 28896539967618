.background {
  background-image: url(../../assets/images/forest.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  height: 100%;
  width: 100%;
}

.landingPageText{
  font-family: 'Roboto Slab';
  color: white;
  margin: auto;
  padding: 20vh 10vh 5vh 2vh;
}

.landingPageText__big {
  font-size: 6vw;
}

.landingPageText__small {
  font-size: 4vw;
}

.landingPageText__phonenumber {
  font-family: 'Roboto', sans-serif;
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  color: white;
  font-size: 2vw;
  padding: 18px 32px;
  border: none;
  cursor: pointer;
  border-radius: 15px;
  text-align: center;
}


